<template>
    <div class="site-body">
        <section class="mypage-section">
            <div class="container container-max-lg">
                <div class="user-thumb">
                    <img
                        src="img/img-user-account@3x.png"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    />
                </div>
                <h1>My Page</h1>
                <h2 class="mb-3 mb-sm-4 mt-3 mt-sm-0">
                    Purchansed amount and Claimable
                </h2>
                <p class="desc">
                    Purchased amount is amount purchased in sale page.
                </p>
                <p class="desc">
                    "Claimable" is the amount which can be moved into your
                    wallet.
                </p>
                <a class="desc-goto"
                    >Learn more <img src="img/ic-more.svg" class="ml-1"
                /></a>
                <div class="referral-box">
                    <div class="referral-row">
                        <div class="img-cont">
                            <img src="img/img-billy-pig.png" />
                        </div>
                        <div class="input-cont">
                            <h5 class="text-20">Purchased amount</h5>
                            <div class="mt-3 copy-btn-wrap">
                                <input
                                    type="text"
                                    class="form-control form-control-lg text-700 text-16"
                                    disabled="disabled"
                                    :value="
                                        localeValue(purchasedAmount) +
                                            ' ' +
                                            prjConst('tokenName')
                                    "
                                />
                            </div>
                            <h5 class="text-20 pt-3 mt-4">Claimable</h5>
                            <div class="mt-3 copy-btn-wrap">
                                <input
                                    type="text"
                                    class="form-control form-control-lg text-700 text-16"
                                    disabled
                                    :value="0"
                                />
                                <button class="btn btn-primary copy-btn">
                                    Claim
                                </button>
                            </div>
                            <h5 class="text-20 pt-3 mt-4">Balance of wallet</h5>
                            <div class="mt-3">
                                <input
                                    type="text"
                                    class="form-control form-control-lg text-700 text-16"
                                    disabled="disabled"
                                    :value="
                                        stakeTokenBalance +
                                            ' ' +
                                            prjConst('tokenName')
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import PresaleMixin from '@/mixins/presale.mixin.vue';
import PrjConst from '@/mixins/prjconst.js';
import {ethers} from 'ethers';
let {utils} = ethers;
const log = require('debug')('app:mypage');

export default {
    mixins: [PrjConst, PresaleMixin],
    setup(props, context) {},
    data() {
        return {
            poolAmount: '0',
            poolLastAmount: '0',
            daysLeft: 0 //days left to token genration
        };
    },
    computed: {
        currentRoute: {
            get() {
                return this.$route.name;
            }
        },
        rewardClaimable() {
            let vm = this;
            if (vm.myStat.rewardFixed && vm.myStat.rewardClaimed) {
                let amt =
                    Number(vm.myStat.rewardFixed) -
                    Number(vm.myStat.rewardClaimed);
                return amt > 0;
            }
            return false;
        }
    },
    watch: {
        walletAddress() {
            this.queryReward();
        }
    },
    async mounted() {
        this.queryReward();
    },
    methods: {
        async queryReward() {
            let vm = this;
            let {crowdSale} = vm.getContracts();

            let rewardStat = await crowdSale.queryRewardStat();

            vm.poolAmount = utils.formatEther(rewardStat.sumDeposit);
            vm.poolLastAmount = utils.formatEther(rewardStat.lastAmount);

            // log('Pool Amount : %s', vm.poolAmount);
            // log('Pool Last Amount : %s', vm.poolLastAmount);
        },
        convertToBtc(value) {
            let vm = this;
            let btcTicker = vm.getMarketTicker('BTCUSDT');
            // console.log('..... BTC Ticker', btcTicker)

            if (btcTicker)
                return vm.localeValue(Number(btcTicker.price) * Number(value));
            return '0';
        },
        calcEstimatedTillClaim(value) {
            let vm = this;
            let btcTicker = vm.getMarketTicker('BTCUSDT');

            if (btcTicker)
                return vm.localeValue(
                    Number(btcTicker.price) * Number(value) * vm.daysLeft
                );
            return '0';
        },
        copyClipboard() {
            let copyText = document.getElementById('myReferralUrl');
            copyText.select();

            try {
                if (document.execCommand) document.execCommand('Copy');
            } catch (e) {}

            try {
                navigator.clipboard.writeText(copyText.value);
            } catch (e) {}
        }
    }
};
</script>
